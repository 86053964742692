import Vue from 'vue'
import Vuex from 'vuex'
import api from '../apis/index'
import {objToQuery} from '../utils/utils'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
  },
  getters: {
    userInfo: state => state.userInfo
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    wxLogin({ commit }, redirect = '') {
      let url = "https://open.weixin.qq.com/connect/qrconnect";
      let params = {
        appid: "wx406fbd817624c872",
        redirect_uri: encodeURIComponent(window.location.href),
        // redirect_uri: encodeURIComponent('http://www.unsugar.com.cn/#/userCenter'),
        response_type: "code",
        scope: "snsapi_login",
        state: "#wechat_redirect",
      };
      url +=
        "?" +
        Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&");

      window.location.href = url;
    },

    login({ commit }, redirect = '') {
      try {
        api.apiGetScanLogin().then(({code, data}) => {
          if (+code === 0) {
            localStorage.setItem('token', data)

            if (redirect) {
              router.push(redirect);
              return;
            }
            router.push('userCenter')
          }
        })
      } catch (error) {
        console.log("🚀 ~ login ~ error:", error)
      }

      // commit('setUserInfo', userInfo)
    },

    async wxLoginByCode({ commit }, params = {}) {
      await api.apiGetLoginNotify({code: params.code}).then(({code, data}) => {
        if (+code === 0) {
          localStorage.setItem('token', data)
          /* if (params.redirect) {
            router.push(params.redirect);
            return;
          }
          router.push('userCenter') */
        }
      })
    },

    /* wxLogin() {
      api.apiGetWxLogin().then(res => {
        console.log(res.data);
      })
    }, */

    scanLogin() {
      // let url = 'https://open.weixin.qq.com/connect/qrconnect?appid=wx5a67899f4af8b0b1&redirect_uri=https%3A%2F%2Fshimo.im%2Flizard-api%2Fauth%2Fwechat%2Flogin_callback&response_type=code&scope=snsapi_login&state=1057bdd4-988e-4057-b6c5-834d57e952f6#wechat_redirect';
      let params = {
        appid: 'wx6349b71cdf51a71b',
        // redirect_uri: encodeURIComponent('https://jjh-frontend.first315.com'),
        // redirect_uri: encodeURIComponent('https://jjh.first315.com'),
        redirect_uri: encodeURIComponent('http://shop.first315.com'),
        response_type: 'code',
        scope: 'snsapi_login',
        state: '',
      }

      let paramsStr = objToQuery(params);
      let url = `https://open.weixin.qq.com/connect/qrconnect?${paramsStr}#wechat_redirect`

      console.log("🚀 ~ scanLogin ~ url:", url)

      /* api.apiGetScanLogin().then(res => {
        console.log(res);
      }) */
    },
  },
  modules: {
  }
})
