<template>
  <div class="layout">
    <!-- 志愿者头部 -->
    <div class="w-full bg-D80C18 navStyle1 text-black py-3 overflow-hidden">
      <div class="lg:max-w-1920px mx-auto px-4 2xl:px-8 flex items-center">
        <div class="flex items-center">
          <img class="w-10 h-9 imgStyle" src="../assets/image/logo-1.png" alt />
          <img class="w-10 h-9" src="../assets/image/logo-2.png" alt />
        </div>

        <div class="ml-8 font-semibold text-xs">上海市青少年发展基金会</div>

        <div class="ml-5 font-semibold text-xs">
          <p>上海市希望工程和青年志愿者指导中心</p>

          <p>（上海市爱心助学事务中心）</p>
        </div>

        <!-- <div
          class="ml-auto flex item-center 2xl:text-base text-sm flex-1 justify-evenly"
        >
          <div
            class="2xl:mx-3 mx-3 flex items-center cursor-pointer"
            v-for="(item, index) of menus"
            :key="index"
            @click="handleClickMenu(item)"
          >
            {{ item.name }}
          </div>
          <div class="flex items-center cursor-pointer" @click="toUserCenter">
            <img
              class="w-10 h-9 2xl:ml-4 ml-2 img2"
              src="../assets/image/Group 1974.png"
              alt
            />
          </div>
        </div> -->

        <div
          class="flex-1 ml-auto flex item-center 2xl:text-base text-sm justify-evenly"
        >
          <div
            class="2xl:mx-3 mx-3 flex items-center cursor-pointer"
            v-for="(item, index) of menus"
            :key="index"
            @click="handleClickMenu(item)"
          >
            <span>{{ item.select ? "" : item.name }}</span>
            <el-dropdown v-if="item.select" @command="handleClickMenu">
              <span class="el-dropdown-link"
                >{{ item.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu style="text-align: center" slot="dropdown">
                <el-dropdown-item
                  v-for="(option, key) of item.options"
                  :key="key"
                  :command="option"
                  >{{ option.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div v-if="!isLoginPage" class="flex items-center cursor-pointer" @click="toUserCenter">
            <img
              v-if="!isLogin"
              class="w-9 h-9 2xl:ml-4 ml-2 img2"
              src="../assets/image/Group 1974.png"
              alt
            />
            <el-dropdown v-else @command="handleCommand">
              <img
                class="w-9 h-9 2xl:ml-4 ml-2 img2"
                src="../assets/image/Group 1974.png"
                alt
              />
              <el-dropdown-menu style="text-align: center" slot="dropdown">
                <el-dropdown-item
                  command="退出登录"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="main-wrapper">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />

      <!-- <router-view /> -->
    </div>

    <!-- 底部 -->
    <div
      class="text-center text-white h-16 flex justify-center items-center text-sm"
      :style="{ background: bgc }"
    >
      <!-- 备案号 -->
      <a>沪ICP备17057002号</a>
      <!-- 公网备案 -->
      <a class="ml-5 flex items-center">
        <img class="w-4 h-4 mr-1" src="../assets/image/icon-2.png" alt />
        沪公网安备 31010102004740号
        <img
          class="w-6 h-6 mr-1"
          src="../assets/image/institution_logo.png"
          alt
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},

  data() {
    return {
      menus: [
        // {
        //   name: "首页",
        //   url: "/volunteer/home",
        // },
        {
          name: "首页",
          url: "/volunteer/home",
          select: true,
          options: [
            {
              name: "希望工程",
              url: "/home",
            },
            {
              name: "青年志愿者",
              url: "/volunteer/home",
            },
          ],
        },
        {
          name: "关于我们",
          url: "/volunteer/aboutUs",
        },
        {
          name: "志愿项目",
          url: "/volunteer/volunteerService",
        },
        {
          name: "志愿动态",
          url: "/volunteer/news",
        },
        {
          name: "志愿风采",
          url: "/volunteer/demeanour",
        },
        {
          name: "政策文件",
          url: "/volunteer/informationDisclosure",
        },
        // {
        //   name: "通知公告",
        //   url: "/volunteer/informationDisclosure",
        // },
      ],
    };
  },
  mounted() {},
  methods: {
    handleClickMenu(item) {
      if (item && item.url && this.$route.path != item.url) {
        // 判断是否是外链
        if (item.url.indexOf("http") > -1) {
          window.open(item.url);
          return;
        }

        this.$router.push(item.url);
      }
    },
    toUserCenter() {
      this.handleClickMenu({
        url: "/userCenter",
      });
    },
    handleCommand(res) {
      if (res === '退出登录') {
        localStorage.removeItem('token');
        this.$router.push('/');
        return;
      }
      console.log("🚀 ~ handleCommand ~ res:", res)
      if (res === "公益活动") return this.$router.push("/volunteer");
      if (res === "爱心助学") return this.$router.push("/loveStudy");
    },
  },
  computed: {
    show() {
      if (this.$route.meta.title === "志愿者") return false;
      else return true;
    },
    bgc() {
      if (this.$route.meta.title === "志愿者") return "#242F55";
      else return "#DF3829";
    },
    isLoginPage() {
      return this.$route.name === 'login';
    },
    isLogin() {
      const token = localStorage.getItem('token')
      return token;
    },
  },
};
</script>
<style lang="less" scoped>
.main-wrapper {
  // min-height: calc(100vh - 64px - 72px);
  // height: calc(100vh - 64px - 72px);
  min-height: calc(100vh - 64px - 64px);
}
.navStyle {
  background: linear-gradient(
    270deg,
    rgba(238, 104, 29, 0.15) 0.81%,
    rgba(216, 12, 24, 0) 100%
  );
  border-color: #ff9e8f;
}

.imgStyle {
  margin-right: 12px;
}
.el-dropdown-link {
  font-size: 16px;
  color: black;
}
.navStyle1 {
  background: linear-gradient(
    270deg,
    rgba(0, 148, 255, 0.15) 0.81%,
    rgba(0, 179, 255, 0) 100%
  );
  border-color: #7abed5;
}

.img2 {
  width: 22.5px;
  height: 22.5px;
}
</style>
