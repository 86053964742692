import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apis from './apis/index.js'
import dayjs from 'dayjs'
Vue.prototype.apis = apis;
Vue.prototype.dayjs = dayjs;
Vue.prototype.$store = store;

Vue.config.productionTip = false

import './assets/css/tailwindcss.css' // 导入 Tailwind CSS 样式

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
