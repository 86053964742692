import axios from './axios';
const token = localStorage.getItem('token')

const api = {
  // ---------- 希望工程首页接口 ----------
  apiGetDashboardHope: (params) => axios.get('/v1/dashboard/hope', { params }),
  
  // 文章列表-信息公开
  apiGetArticleList: (params) => axios.get('v1/article/list', { params }),
  
  // 文章分类(信息公开)
  apiGetArticleCategoryList: (params) => axios.get('/v1/article/category/list', { params }),

  // 文章详情
  apiGetArticleDetail: (id) => axios.get(`/v1/article/detail/${id}`),


  // ---------- 品牌项目/实施项目 ----------
  
  // 项目分类
  apiGetProjectCategoryList: (id) => axios.get(`/v1/project/category/list/${id}`),

  // 项目列表
  apiGetProjectList: (params) => axios.get('/v1/project/list', { params }),

  // 项目详情
  apiGetProjectDetail: (id) => axios.get(`/v1/project/detail/${id}`),

  // 捐款记录
  apiGetProjectDonationsList: (params) => axios.get('/v1/project/donations/list', { params }),

  // 精选留言
  apiGetProjectSayList: (params) => axios.get('/v1/project/say/list', { params }),

  // 执行情况
  apiGetProjectExecuteList: (params) => axios.get('/v1/project/execute/list', { params }),

  // 捐款下单
  apiPostCreateDonationsOrder: (data) => axios.post('/v1/order/createDonationsOrder', data),



  // ---------- 在线义卖 ----------
  // 商品列表
  apiProjectGoodsList: (params) => axios.get('/v1/project/goodsList', {params}),

  // 志愿者首页 /v1/dashboard/volunteer
  apiGetVolunteerDashboard: (params) => axios.get('/v1/dashboard/volunteer', {params}),

  // 商品下单
  apiCreateOrder: (params) => axios.post('/v1/order/createOrder', params),

  // ---------- 爱心助学 ----------
  apiGetLovelyGoods: (params) => axios.get('/v1/project/lovelyGoods', {params}),
  
  // 爱心助学详情
  apiGetLovelyGoodsDetail: (stuId) => axios.get(`/v1/project/lovelyGoodsDetail/${stuId}`),


  // 微信登录
  // /user/mp/wxLogin
  apiGetWxLogin: () => axios.get('/v1/user/mp/wxLogin'),

  // 微信PC扫码登录
  apiGetScanLogin: () => axios.get('/v1/user/mp/scanLogin'),

  // 微信手机登陆(返回token)
  apiGetLoginNotify: (params) => axios.get('/v1/user/mp/loginNotify', {
    params,
  }),

  // 查看订单状态
  apiGetOrderDetail: (orderNo) => axios.get(`/v1/order/detail/${orderNo}`),
  
  // 用户捐款信息
  apiGetUserInfo: (params) => axios.get('/v1/personal/getUserInfo', {
    params,
  }),

  // 用户捐款记录
  apiPersonalRecord: (params) => axios.get('/v1/personal/record', {
    params,
  }),

  // 证书列表
  apiPersonalCertList: (params) => axios.get('/v1/personal/certList', {
    params,
  }),
  
  // 执行情况
  apiPersonalExeList: (params) => axios.get('/v1/personal/exeList', {
    params,
  }),

  // 个人中心爱心助学反馈列表（捐方）
  // /v1/personal/feedbackList?type=1
  apiPersonalFeedbackList: (params) => axios.get('/v1/personal/feedbackList', {
    params,
  }),

  // 捐赠证书
  apiPersonalCertDetail: (params) => axios.get('/v1/personal/certDetail', {
    params,
  }),

}

export default api;