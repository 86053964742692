import axios from 'axios'
import { Dialog } from 'vant';
const qs = require('qs')
import { Toast } from 'vant'

console.log(process.env);


let baseURL = process.env.VUE_APP_BASE_URL
// if (process.env.NODE_ENV === 'production') {
//   baseURL = `${window.location.protocol}//${baseURL}`
// }
// console.log(baseURL);

// const baseURL = '/'

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 10000
});


// 声明一个 Map 用于存储每个请求的标识 和 取消函数
const pending = new Map()
/**
 * 添加请求
 * @param {Object} config 
 */
const addPending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data)
  ].join('&')
  config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
    if (!pending.has(url)) { // 如果 pending 中不存在当前请求，则添加进去
      pending.set(url, cancel)
    }
  })
}
/**
 * 移除请求
 * @param {Object} config 
 */
const removePending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data)
  ].join('&')
  if (pending.has(url)) { // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    const cancel = pending.get(url)
    cancel(url)
    pending.delete(url)
  }
}
/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url)
  }
  pending.clear()
}


instance.interceptors.request.use(config => {
  removePending(config) // 在请求开始前，对之前的请求做检查取消操作
  addPending(config) // 将当前请求添加到 pending 中
  let token = window.localStorage.getItem('token') || ''
  // token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MTAwNDY3ODYsInVzZXJuYW1lIjoibzRQQUc2YkhOMi14WURhOUFuN2xkQUZzVWxqVSJ9.d9BwfpLltp8-OG0VqD11wSMFpdPdc-CF_bqr7tyJ9ak'
  config.headers.token = token
  return config
}, err => {
  return err
});


instance.interceptors.response.use(res => {
  removePending(res) // 在请求结束后，移除本次请求
  if (res?.data?.code === 0) {
    return Promise.resolve(res.data || {})
  } else {
    Toast(res?.data?.msg || '请求出错')    

    return res.data || {}
    // return Promise.reject(res.data || {})
  }
}, err => {
  if (err?.response?.status === 401) {
    window.localStorage.setItem('token', '')
    Dialog.alert({
      title: '',
      message: '请先登录',
      confirmButtonText: '去登录',
    }).then(() => {
      window.open(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.sessionStorage.getItem('appId') || 'wxb86e15e22504c786'}&redirect_uri=${encodeURIComponent(location.href)}&response_type=code&scope=snsapi_userinfo&state=&connect_redirect=1#wechat_redirect`)
    }).catch(() => {
    
    });
    return {}
  }
  return err
});



export default instance